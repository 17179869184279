import React, {
  useState, useRef, useCallback, useEffect
} from 'react';
import {
  shape, bool, string, oneOf, func, arrayOf
} from 'prop-types';
import {
  useDataModel
} from '@thd-nucleus/data-sources';
import {
  Tabs, Tab, Image, Button
} from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import { useConfigService } from '@thd-nucleus/experience-context';
import { VideoPlayerProvider, VideoPlayer, pauseAllVideos } from '@thd-olt-component-react/video-player';
import { Drawer, DrawerItem, DrawerHeader, DrawerFooter } from '@thd-olt-component-react/drawer';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { MediaHotspots } from '@thd-olt-component-react/product-hotspots';
import { PaintSwatches } from '@one-thd/sui-icons';
import { mediaClick } from '../../../publisher';
import Threesixty from '../Threesixty/Threesixty';
import { dataModel } from '../../dataModel';
import Helper from '../../Helper';
import { AvataarIFrame } from '../MediaGalleryOverlay/components/AvataarIFrame/AvataarIFrame';
import './filmstrip-mobile.style.scss';

export const FilmstripMobile = ({
  itemId,
  data,
  isOpen,
  setIsOpen,
  openImage,
  setOpenImage,
  firstItemFetchPriority,
  selectedTab,
  showAvataarDefault,
  showAvataarAR,
  onAvataarButtonClick
}) => {
  const imgRef = useRef();
  const quickPinchZoomRef = useRef();
  const [mainAsset, setMainAsset] = useState(null);
  const showUserImages = useConfigService('fs-prop:filmstrip-user-images-ab') ?? false;
  const userImageMinimumRating = useConfigService('fs-prop:filmstrip-user-images-minimum-rating') ?? 1;

  // zoom function
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty('transform', value);
    }
  }, []);
  const threeSixtyRef = useRef();

  const { displayedMedia } = data;
  const { main, zoom } = displayedMedia;

  const formatTabName = (tabName) => {
    return tabName.toLowerCase()
      .replace(/\s/g, '-') // replace spaces with dashes
      .replace(/[^A-Za-z0-9-]/g, ''); // remove non-alphanumeric characters other than dashes
  };

  const scrollToOpenImage = () => {
    if (openImage) {
      const portrait = window.matchMedia('(orientation: portrait)').matches;

      const image = document.querySelector(`.drawer-media [data-image-url='${openImage}']`);
      const container = document.querySelector(`#filmstrip-tab-content-${formatTabName(selectedTab)}`);
      let containerOffset;
      let imageOffset;
      let imagePosition;

      if (portrait) {
        containerOffset = Math.floor((container?.getBoundingClientRect()?.height ?? 0) / 2);
        imageOffset = Math.floor((image?.getBoundingClientRect()?.height ?? 0) / 2);
        imagePosition = (image?.offsetTop ?? 0);
      } else {
        imageOffset = Math.floor((image?.getBoundingClientRect()?.width ?? 0) / 2);
        containerOffset = Math.floor((container?.getBoundingClientRect()?.width ?? 0) / 2);
        imagePosition = (image?.offsetLeft ?? 0);
      }

      const scrollTarget = imagePosition - containerOffset + imageOffset;

      if (container) {
        container.scrollTo({
          left: portrait ? undefined : scrollTarget,
          top: portrait ? scrollTarget : undefined,
          behavior: 'auto'
        });
      }
    }
  };

  useEffect(scrollToOpenImage, [openImage]);

  const getSortedMedia = (mediaArray) => {
    const media = {};

    if (!mediaArray) {
      return media;
    }

    (mediaArray).forEach((image) => {
      if (Helper.isVideo({ media: image })) {
        if (!media.Videos) {
          media.Videos = [];
        }
        media.Videos.push(image);
      } else if (Helper.isImage360({ media: image })) {
        if (!media['360° View']) {
          media['360° View'] = [];
        }
        media['360° View'].push(image);
      } else if (!Helper.isAR({ media: image })) {
        if (!media.Images) {
          media.Images = [];
        }
        media.Images.push(image);
      } else if (Helper.isAR({ media: image }) && (showAvataarDefault || showAvataarAR)) {
        if (!media.AR) {
          media.AR = [];
        }
        media.AR.push(image);
      }
    });

    const sortedMedia = Object.entries(media).sort((mediaA, mediaB) => {
      if (mediaA[1].length !== 0 && mediaB[1].length === 0) {
        return -1;
      }
      if (mediaA[1].length === 0 && mediaB[1].length !== 0) {
        return 1;
      }
      return 0;
    });
    return sortedMedia;
  };

  const sortedMain = getSortedMedia(main);
  const sortedZoom = getSortedMedia(zoom);

  const reviewPhotos = useDataModel('reviewPhotos', { variables: { itemId: itemId || '' }, skip: !itemId });

  let customerImages = [];
  if (reviewPhotos.data) {
    customerImages = reviewPhotos.data?.reviewPhotos?.Results?.reduce((accum, current) => {
      if (current.Rating >= userImageMinimumRating) {
        current.Photos.forEach((photo) => {
          accum.push({
            url: photo.Sizes.normal.Url,
            id: photo.Id,
            rating: current.Rating
          });
        });
      }

      return accum;
    }, []);
  }

  if (showUserImages && customerImages.length > 0) {
    sortedMain.push(['Customer Images', customerImages]);
    sortedZoom.push(['Customer Images', customerImages]);
  }

  const getTabIndex = () => {
    if (Array.isArray(sortedMain)) {
      const element = sortedMain?.find((el) => el[0] === selectedTab);
      const index = sortedMain?.indexOf(element);

      return index;
    }
    return 0;
  };

  const [tabIndex, setTabIndex] = useState(getTabIndex());

  useEffect(() => {
    setTabIndex(getTabIndex());
  }, [selectedTab]);

  const onChange = ({ index }) => {
    setTabIndex(index);

    let target = formatTabName(sortedMain[index][0]);

    if (target === '360-view') {
      target = 'threesixty';
    }

    const payload = {
      section: 'expanded-view',
      component: 'thumbnails',
      target,
      position: index + 1
    };
    mediaClick(payload);

    if (sortedMain[index][0] !== 'Videos') {
      pauseAllVideos();
    }
  };

  const MediaTabs = () => sortedMain?.map((value) => {
    return (
      <Tab
        key={value[0]}
        label={`${value[0]} ${value[0] !== '360° View' ? ('(' + value[1].length + ')') : ''}`}
        disabled={value[1].length === 0}
      />
    );
  });

  const { brandName, galleryLabel } = data;

  let onImageClick = (selectedImage, mediaIndex) => {
    // Analytics
    const payload = {
      section: 'media',
      component: 'thumbnails',
      target: 'image',
      position: 1 + mediaIndex,
      guid: Helper.getImageGuid(selectedImage)
    };

    mediaClick(payload);

    setMainAsset(selectedImage);
  };

  const MediaContent = () => {
    return sortedMain.map((mediaTab, mediaTabIndex) => {
      const mediaType = formatTabName(mediaTab[0]);
      const avataarExperienceLink = Helper.getAvataarExperienceLink(itemId, showAvataarAR);

      return (
        <div
          id={`filmstrip-tab-content-${mediaType}`}
          className="drawer-media"
          style={{ display: (tabIndex === mediaTabIndex ? '' : 'none') }}
          data-testid={`${mediaType}-drawer-media`}
          key={mediaTabIndex}
        >
          {mediaTab[1].map((value, mediaIndex) => {
            const { url, altText, videoStill, rgb } = value;
            const isVideo = Helper.isVideo({ media: value });
            const isThreeSixty = Helper.isImage360({ media: value });
            const isHotspot = Helper.isHotspot({ media: value });
            const isAR = Helper.isAR({ media: value });
            const isCustomColor = Helper.isCustomColor({ media: value });
            const isManualColor = Helper.isManualColor({ media: value });
            const isCustomerImage = mediaType === 'customer-images';

            let imageUrl = url;
            const alt = altText || `${brandName} - ${galleryLabel}`;

            if (isVideo) {
              const { source, videoSource, videoId } = Helper.formatVideoProps(value);
              return (
                <div className="_video" key={value.id} id={`video-${mediaIndex}`} data-image-url={videoStill}>
                  <VideoPlayer
                    aspectRatio={null}
                    videoId={videoId}
                    source={source}
                    videoSrc={videoSource}
                    analyticsData={{
                      section: 'expanded-view',
                      component: 'thumbnails',
                      position: (mediaTabIndex + 1).toString()
                    }}
                  />
                </div>
              );
            }
            if (isThreeSixty) {
              return (
                <React.Fragment key={value.id}>
                  <div className="three-sixty__wrapper">
                    <Threesixty media={value} ref={threeSixtyRef} />
                  </div>
                  <div className="reset-bttn__wrapper">
                    <Button className="reset-bttn" outline onClick={() => { threeSixtyRef.current.resetImage(); }}>
                      Reset
                    </Button>
                  </div>
                </React.Fragment>
              );
            }

            if (isHotspot) {
              return (
                <div key={value.id} className="_hotspot" data-image-url={url}>
                  <MediaHotspots hotspots={value?.hotspots} imageUrl={url} />
                </div>
              );
            }

            if (isCustomerImage) {
              return (
                <Image
                  key={value.id}
                  className="_customer-image"
                  alt={`${brandName} - ${galleryLabel}`}
                  src={imageUrl}
                  onClick={() => onImageClick(sortedZoom[mediaTabIndex][1][mediaIndex])}
                  lazy={mediaIndex > 1}
                  fetchpriority={mediaIndex === 0 ? firstItemFetchPriority : null}
                  data-review-rating={`rating-${value.rating}`}
                />
              );
            }

            if (isAR && avataarExperienceLink && showAvataarDefault) {
              return (<AvataarIFrame key={value.id} avataarExperienceLink={avataarExperienceLink} />);
            }

            if (isCustomColor) {
              return (
                <div
                  key={value.id}
                  className="mobile__paint-reorder-color"
                  style={{
                    backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
                  }}
                  data-image-url={value.id}
                >
                  <Image
                    alt={alt}
                    src={value.brandLogo}
                  />
                </div>
              );
            }

            if (isManualColor) {
              const manualColorStyle = classNames('sui-min-w-full',
                'manualSwatch',
                'sui-aspect-square',
                'sui-bg-inactive',
                'sui-flex',
                'sui-items-center',
                'sui-justify-center');
              return (
                <div
                  key={value.id}
                  className={manualColorStyle}
                >
                  <div className="sui-text-center sui-flex sui-items-center sui-justify-center">
                    <div className="sui-h-auto">
                      <PaintSwatches color="medium" />
                      <div>No Color Preview Available</div>
                    </div>
                  </div>
                  <div
                    className="mobile__paint-reorder-color sui-absolute"
                  >
                    <Image
                      alt={alt}
                      src={value.brandLogo}
                    />
                  </div>
                </div>
              );
            }

            return (
              <Image
                key={value.id}
                className="_image"
                alt={alt}
                src={imageUrl}
                data-image-url={imageUrl}
                onClick={() => onImageClick(sortedZoom[mediaTabIndex][1][mediaIndex], mediaIndex)}
                lazy={imageUrl !== openImage}
                fetchpriority={mediaIndex === 0 ? firstItemFetchPriority : null}
                width={600}
                height={600}
              />
            );
          })}
        </div>
      );
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setOpenImage(null);
    pauseAllVideos();
    if (onAvataarButtonClick) {
      setTimeout(() => {
        onAvataarButtonClick({ showAvataarAR: false });
      }, 1000);
    }
  };

  return (
    <div className="filmstrip-mobile" data-testid="filmstrip-overlay">
      <Drawer
        initialItem="filmstrip-mobile"
        onClose={handleClose}
        open={isOpen}
        maxSize={100}
        isMobile
        isFlex
      >
        <DrawerItem name="filmstrip-mobile">
          <VideoPlayerProvider>
            {/** The title for the drawer header is given empty to remove the back button */}
            <DrawerHeader title=" ">
              <Tabs id="tabs-wrapper" value={tabIndex} onChange={onChange}>
                {MediaTabs()}
              </Tabs>
            </DrawerHeader>
            {MediaContent()}
            <Drawer
              initialItem="filmstrip-mobile-main-asset"
              onClose={(fromCloseButton) => {
                // resets zoom
                quickPinchZoomRef.current.scaleTo({ x: 0, y: 0, scale: 1 });
                setMainAsset(null);
                if (fromCloseButton) {
                  setIsOpen(false);
                }
              }}
              open={!!mainAsset}
              maxSize={100}
              isMobile
              isFlex
            >
              <DrawerItem
                name="filmstrip-mobile-main-asset"
              >
                <DrawerHeader />
                <QuickPinchZoom
                  onUpdate={onUpdate}
                  maxZoom={3}
                  draggableUnZoomed={false}
                  ref={quickPinchZoomRef}
                  containerProps={{
                    className: 'quick-pinch-zoom'
                  }}
                >
                  <img
                    width="auto"
                    height="auto"
                    className="_image"
                    src={mainAsset?.url}
                    alt={(mainAsset ? `${brandName} - ${galleryLabel}` : '')}
                    ref={imgRef}
                  />
                </QuickPinchZoom>
                <DrawerFooter>
                  <p className="pinch-to-zoom">Pinch to Zoom</p>
                </DrawerFooter>
              </DrawerItem>
            </Drawer>
          </VideoPlayerProvider>
        </DrawerItem>
      </Drawer>
    </div>
  );
};

FilmstripMobile.displayName = 'FilmstripMobile';

FilmstripMobile.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  itemId: string,
  data: shape({
    displayedMedia: shape({
      main: arrayOf(shape({})),
      thumbnails: arrayOf(shape({})),
      zoom: arrayOf(shape({}))
    }).isRequired,
    brandName: string,
    galleryLabel: string
  }).isRequired,
  firstItemFetchPriority: oneOf([
    'high', // Fetch the image at a high priority relative to other images.
    'low', // Fetch the image at a low priority relative to other images.
    'auto' // Indicates no preference for the fetch priority. The browser decides what is best for the user.
  ]),
  openImage: string,
  setOpenImage: func.isRequired,
  selectedTab: oneOf([
    'Images',
    'Videos',
    '360° View',
    'AR',
    null
  ]),
  showAvataarDefault: bool,
  showAvataarAR: bool,
  onAvataarButtonClick: func
};

FilmstripMobile.defaultProps = {
  itemId: null,
  firstItemFetchPriority: 'auto',
  openImage: null,
  selectedTab: 'Images',
  showAvataarDefault: false,
  showAvataarAR: false,
  onAvataarButtonClick: null
};

FilmstripMobile.dataModel = dataModel;
